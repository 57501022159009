<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <!--begin::Card-->
          <div class="card">
            <div class="card-body p-10 p-lg-15">
              <h1 class="anchor fw-bold mb-5" id="overview">Result Settings</h1>
              <div class="py-5">
                Catalog result settings are set through the REST API. List of uploaded
                <router-link
                    to="/result-settings/catalog"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <span class="menu-text">Result Settings Front API</span>
                  </a>
                </router-link>.
              </div>

              <h2 class="anchor fw-bold mb-5" id="overview">Autocomplete/SERP</h2>
    <v-text-field
      label="Query test"
      ref="r_query_test"
      v-model="queryString"
      outlined
    ></v-text-field>
    <div
      :class="`alert alert-${message.color}`"
      role="alert"
      v-if="message.show"
    >
      {{ message.text }}
    </div>
            </div>
          </div>
          <!--end::Card-->
        </div>
      </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Autocomplete
                <small>sub title</small>
              </h3>
            </div>
            <div class="card-toolbar">
              <a
                href="#"
                class="btn btn-info"
                ref="submit"
                @click.prevent="saveAutocomplete"
                >Save</a
              >
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h3>Results</h3>
                <div class="form-group">
                  <v-col class="d-flex">
                    <v-select
                      v-model="autocomplete.phrase"
                      :items="autocomplete.phrases"
                      filled
                      label="Phrase Items count"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex">
                    <v-text-field
                      v-model="autocomplete.phrase_label"
                      type="text"
                      outlined
                      label="Phrases Label"
                    ></v-text-field>
                  </v-col>
                </div>
                <div role="tablist">
                  <b-card
                    no-body
                    class="mb-1"
                    v-for="collection in autocomplete.collections"
                    :key="collection.id"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <div
                        class="d-flex align-items-center mb-9 bg-light rounded p-5"
                        v-b-toggle="'accordion-' + collection.id"
                        variant="info"
                        @click.prevent=""
                      >
                        <!--begin::Icon-->
                        <span class="svg-icon svg-icon-warning mr-5">
                          <span class="svg-icon svg-icon-lg"> </span>
                        </span>
                        <!--end::Icon-->
                        <!--begin::Title-->
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                          <a
                            href="#"
                            class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                            >{{ collection.name }}<span v-if="collection.frontapi"> - FrontApi</span></a
                          >
                        </div>
                        <!--end::Title-->
                      </div>
                    </b-card-header>
                    <b-collapse
                      v-bind:id="'accordion-' + collection.id"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-sub-title>Collection Search</b-card-sub-title>
                        <v-switch
                          v-model="collection.searchable"
                          label="Search in this collection"
                        ></v-switch>

                        <section v-if="collection.searchable">
                          <v-select
                            label="Layout Column"
                            ref="c_layout_column_id"
                            item-text="name"
                            item-value="id"
                            :rules="[v => !!v || 'Item is required']"
                            v-model="collection.layout_column_id"
                            :items="layout_columns"
                            required
                          ></v-select>
                          <v-text-field
                            v-model="collection.count"
                            :rules="[rules.isNumeric]"
                            counter="25"
                            label="Count of items"
                            outlined
                          ></v-text-field>

                          <v-text-field
                            v-model="collection.ordinal_number"
                            :rules="[rules.isNumeric]"
                            counter="3"
                            label="Ordinal Number"
                            outlined
                          ></v-text-field>
                          <v-text-field
                            v-model="collection.label"
                            type="text"
                            outlined
                            label="Label"
                          ></v-text-field>
                          <v-switch
                            v-model="collection.show_as_simple"
                            label="Show results as simple render"
                          ></v-switch>
                          <v-container v-if="collection.fields.length > 0">
                          <hr />
                          <b-card-sub-title>Fields</b-card-sub-title>
                          <v-row

                            v-for="field in collection.fields"
                            :key="field.id"
                          >
                            <strong v-if="field.can_be_aggregate || !collection.frontapi">{{ field.name }}</strong>
                            <v-col cols="12">
                              <v-switch
                                v-if="!collection.frontapi"
                                v-model="field.response"
                                label="Response"
                              ></v-switch>
                              <v-switch
                                v-if="field.response"
                                v-model="field.array"
                                label="Return as array"
                              >
                              </v-switch>
                              <v-text-field
                                v-if="field.response && field.can_be_aggregate"
                                v-model="field.cutter"
                                type="number"
                                outlined
                                label="Limit text"
                              >
                              </v-text-field>
                              <v-switch
                                v-if="field.can_be_aggregate"
                                v-model="field.filter"
                                label="Aggregate"
                              ></v-switch>

                              <v-select
                                v-if="field.filter"
                                label="Layout Column"
                                ref="f_layout_column_id"
                                item-text="name"
                                item-value="id"
                                :rules="[v => !!v || 'Item is required']"
                                v-model="field.layout_column_id"
                                :items="layout_columns"
                                required
                              ></v-select>
                              <v-text-field
                                v-if="field.filter"
                                v-model="field.label"
                                type="text"
                                outlined
                                label="Label"
                              >
                              </v-text-field>

                              <v-text-field
                                v-if="field.filter"
                                v-model="field.ordinal_number"
                                :rules="[rules.isNumeric]"
                                counter="3"
                                label="Ordinal Number"
                                outlined
                              ></v-text-field>
                              <v-text-field
                                v-if="field.filter"
                                v-model="field.aggregate_count"
                                type="number"
                                outlined
                                label="Aggregate Count"
                              >
                                <v-switch
                                  v-if="field.can_be_aggregate"
                                  v-model="field.highlight"
                                  label="Highlight"
                                ></v-switch>
                              </v-text-field>
                            </v-col>
                          </v-row>
                          </v-container>
                        </section>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
              <div class="col-md-6 bg-dark text-white">
                <pre class="language-json text-white">{{
                  JSON.stringify(autocomplete.response, null, "\t")
                }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Search
                <small>sub title</small>
              </h3>
            </div>
            <div class="card-toolbar">
              <a
                href="#"
                class="btn btn-info"
                ref="submit"
                @click.prevent="saveSearch"
                >Save</a
              >
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h3>Results</h3>
                <div role="tablist">
                  <b-card
                    no-body
                    class="mb-1"
                    v-for="collection in search.collections"
                    :key="collection.id"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <div
                        class="d-flex align-items-center mb-9 bg-light rounded p-5"
                        v-b-toggle="'accordion-search-' + collection.id"
                        variant="info"
                        @click.prevent=""
                      >
                        <!--begin::Icon-->
                        <span class="svg-icon svg-icon-warning mr-5">
                          <span class="svg-icon svg-icon-lg"> </span>
                        </span>
                        <!--end::Icon-->
                        <!--begin::Title-->
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                          <a
                            href="#"
                            class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                            >{{ collection.name }}</a
                          >
                        </div>
                        <!--end::Title-->
                      </div>
                    </b-card-header>
                    <b-collapse
                      v-bind:id="'accordion-search-' + collection.id"
                      visible
                      accordion="accordion-search"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-sub-title>Collection Search</b-card-sub-title>
                        <v-switch
                          v-model="collection.searchable"
                          label="Search in this collection"
                        ></v-switch>

                        <section v-if="collection.searchable">
                          <v-text-field
                            v-model="collection.count"
                            :rules="[rules.isNumeric]"
                            counter="25"
                            label="Count of items"
                            outlined
                          ></v-text-field>
                          <v-text-field
                            v-model="collection.ordinal_number"
                            :rules="[rules.isNumeric]"
                            counter="3"
                            label="Ordinal Number"
                            outlined
                          ></v-text-field>
                          <v-select
                            label="Set as Additional Item of"
                            ref="f_parent_id"
                            item-text="name"
                            item-value="id"
                            v-model="collection.parent_id"
                            :items="collections"
                            required
                          ></v-select>
                          <v-text-field
                            v-if="collection.searchable"
                            v-model="collection.label"
                            type="text"
                            outlined
                            label="Label"
                          >
                          </v-text-field>
                          <v-container v-if="collection.fields.length > 0">

                            <hr />
                            <b-card-sub-title>Fields</b-card-sub-title>
                            <v-row
                              v-for="field in collection.fields"
                              :key="field.id"
                            >
                              <strong>{{ field.name }}</strong>
                              <v-col cols="12">
                                <v-switch
                                  v-model="field.response"
                                  label="Response"
                                ></v-switch>
                                <v-switch
                                  v-if="field.response"
                                  v-model="field.array"
                                  label="Return as array"
                                >
                                </v-switch>
                                <v-switch
                                  v-if="field.can_be_filter"
                                  v-model="field.filter"
                                  label="Filter"
                                ></v-switch>
                                <v-text-field
                                  v-if="field.filter"
                                  v-model="field.label"
                                  type="text"
                                  outlined
                                  label="Label"
                                >
                                </v-text-field>
                                <v-text-field
                                  v-if="field.filter"
                                  v-model="field.ordinal_number"
                                  :rules="[rules.isNumeric]"
                                  counter="3"
                                  label="Ordinal Number"
                                  outlined
                                ></v-text-field>
                                <v-text-field
                                  v-if="field.filter"
                                  v-model="field.suffix"
                                  type="text"
                                  outlined
                                  label="Suffix"
                                >
                                </v-text-field>

                                <v-switch
                                  v-if="field.can_be_sort"
                                  v-model="field.sort_use"
                                  label="Sort"
                                ></v-switch>
                                <v-text-field
                                  v-if="field.sort_use"
                                  v-model="field.sort_asc"
                                  type="text"
                                  outlined
                                  label="Sort ASC label"
                                >
                                </v-text-field>
                                <v-text-field
                                  v-if="field.sort_use"
                                  v-model="field.sort_desc"
                                  type="text"
                                  outlined
                                  label="Sort DESC label"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
              <div class="col-md-6 bg-dark text-white">
                <pre class="language-json text-white">{{
                  JSON.stringify(search.response, null, "\t")
                }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  GET_AUTOCOMPLETE,
  GET_LIST_COLLECTIONS,
  GET_SEARCH,
  PULL_LAYOUT_COLUMNS,
  PUT_AUTOCOMPLETE,
  PUT_SEARCH
} from "@/core/services/store/result_settings.module";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";

export default {
  data() {
    return {
      valid: true,
      phrases: [
        { text: "Off", value: 0 },
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        {
          text: "3",
          value: 3
        },
        { text: "4", value: 4 },
        { text: "5", value: 5 }
      ],
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: value => {
          const pattern = /^(https?:\/\/)+[\w\\-~]+(\.[\w\\-~]+)+(\/[\w\-~]*)*(#[\w\\-]*)?(\\?.*)?$/;
          return pattern.test(value) || "Invalid website url.";
        },
        isNumeric: value => {
          return !isNaN(parseFloat(value)) && isFinite(value);
        }
      },
      filterFields: "",
      filterBuffer: "",
      searchBuffer: "",
      searchString: "",
      queryString: "test"
    };
  },
  methods: {
    reloadData() {
      this.$store.dispatch(RELOAD_MESSAGE);
      this.$store.dispatch(GET_AUTOCOMPLETE);
      this.$store.dispatch(GET_SEARCH);
      this.$store.dispatch(PULL_LAYOUT_COLUMNS);
      this.$store.dispatch(GET_LIST_COLLECTIONS);
    },
    saveAutocomplete() {
      this.$store
        .dispatch(PUT_AUTOCOMPLETE, {
          data: this.autocomplete,
          query: this.queryString
        })
        .then(() => {
          this.$store.dispatch(GET_AUTOCOMPLETE, "?search=" + this.queryString);
        });
    },
    saveSearch() {
      this.$store
        .dispatch(PUT_SEARCH, {
          data: this.search,
          query: this.queryString
        })
        .then(() => {
          this.$store.dispatch(GET_SEARCH, "?search=" + this.queryString);
        });
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.resultSetting.errors,
      message: state => state.message.message,
      loading: state => state.resultSetting.loading,
      autocomplete: state => state.resultSetting.autocomplete,
      layout_columns: state => state.resultSetting.layout_columns,
      search: state => state.resultSetting.search,
      collections: state => state.resultSetting.collections
    }),
    ...mapGetters(["currentProject"])
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Result Settings" }]);
  }
};
</script>
